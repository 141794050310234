import React, { Component } from "react";

import "./style.css";

// material UI imports
import {
  Button,
  TextField,
  InputLabel,
  MenuItem,
  Select,
  FormControl,
  Box,
  CircularProgress,
  Chip,
} from "@material-ui/core";
import { Row, Col, Button as Btn1, Modal, Badge } from "react-bootstrap";

// font awasome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPenAlt,
  faEye,
  faTrash,
  faSyncAlt,
} from "@fortawesome/free-solid-svg-icons";

// Toastify imports
import { ToastContainer, toast } from "react-toastify";

//API handling components
import { API_URL } from "./../../global";

import AddNewRoute from "./AddNewRoute";
import PartyList from "./PartyList";

// datatable setup
import jsZip from "jszip";
window.JSZip = jsZip;
var $ = require("jquery");
$.DataTable = require("datatables.net");
require("datatables.net-bs4");
require("datatables.net-autofill-bs4");
require("datatables.net-buttons-bs4");
require("datatables.net-buttons/js/buttons.colVis");
require("datatables.net-buttons/js/buttons.flash");
require("datatables.net-buttons/js/buttons.html5");
require("datatables.net-buttons/js/buttons.print");
require("datatables.net-responsive-bs4");
require("datatables.net-scroller-bs4");
require("datatables.net-select-bs4");
require("pdfmake");

// constants
const axios = require("axios");

export default class RouteManager extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showUpdateModel: false,
      routesData: null,

      activeRouteId: null,
      activeName: "",
      activeCities: [],
      newCities: [],
      cityOptions: [],

      isLoading: false,
    };
  }

  fetchCityOptions() {
    let url = API_URL;

    const query = `SELECT DISTINCT city FROM party`;
    let data = {
      crossDomain: true,
      crossOrigin: true,
      query: query,
    };
    axios
      .post(url, data)
      .then((res) => {
        console.log("city options data : ", res.data);
        this.setState({ cityOptions: res.data.map((record) => record.city) });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  fetchRoutesData() {
    let url = API_URL;
    const query = `SELECT * FROM routes;`;
    let data = { crossDomain: true, crossOrigin: true, query: query };
    this.setState({ isLoading: true });
    axios
      .post(url, data)
      .then((res) => {
        console.log("routes data: ", res.data);
        this.setState({ routesData: res.data, isLoading: false });
      })
      .catch((err) => {
        console.log("routes data error: ", err);
        this.setState({ isLoading: false });
      });
  }

  fetchActiveCities() {
    let url = API_URL;
    const query = `SELECT id, cityName as city FROM routeList where routeId=${this.state.activeRouteId};`;
    let data = { crossDomain: true, crossOrigin: true, query: query };
    axios
      .post(url, data)
      .then((res) => {
        console.log("active cities data: ", res.data);
        let cities = res.data.map((record) => record.city);
        this.setState({ activeCities: cities, newCities: cities });
      })
      .catch((err) => {
        console.log("active cities data error: ", err);
      });
  }

  updateCities() {
    let url = API_URL;

    const newCities = this.state.newCities.filter(
      (city) => !this.state.activeCities.includes(city)
    );
    const deletedCities = this.state.activeCities.filter(
      (city) => !this.state.newCities.includes(city)
    );

    Promise.all(
      newCities.map((city) => {
        const query = `INSERT INTO routeList(routeId, cityName) VALUES(${this.state.activeRouteId}, '${city}')`;
        let data = {
          crossDomain: true,
          crossOrigin: true,
          query: query,
        };
        return axios.post(url, data);
      })
    )
      .then((res) => {
        console.log("new cities added response : ", res);
      })
      .catch((err) => {
        console.log("new cities added error : ", err);
      });

    Promise.all(
      deletedCities.map((city) => {
        const query = `DELETE FROM routeList WHERE routeId=${this.state.activeRouteId} and cityName='${city}'`;
        let data = {
          crossDomain: true,
          crossOrigin: true,
          query: query,
        };
        return axios.post(url, data);
      })
    )
      .then((res) => {
        console.log("cities deleted response : ", res);
      })
      .catch((err) => {
        console.log("cities deleted error : ", err);
      });
  }

  handleUpdateSubmit(e) {
    e.preventDefault();
    let url = API_URL;

    const query = `UPDATE routes SET name="${this.state.activeName}" WHERE id=${this.state.activeRouteId};`;
    let data = {
      crossDomain: true,
      crossOrigin: true,
      query: query,
    };
    axios
      .post(url, data)
      .then((res) => {
        toast.success("Route details updated successfully");
        this.updateCities();
        this.fetchRoutesData();
      })
      .catch((err) => {
        console.log("error while updating route data", err);
      });
  }

  deleteRecord(id) {
    let url = API_URL;
    const query = `DELETE FROM routes WHERE id=${id};`;
    let data = { crossDomain: true, crossOrigin: true, query: query };
    axios
      .post(url, data)
      .then((res) => {
        console.log("deleted route data: ", res.data);
        toast.error("Route deleted successfully");
        this.fetchRoutesData();
      })
      .catch((err) => {
        console.log("record delete error: ", err);
      });
  }

  refreshParties() {
    window.location.reload(false);
  }

  componentDidMount() {
    this.fetchRoutesData();
    this.fetchCityOptions();
  }

  componentDidUpdate() {
    const title = "Routes list";
    $("#route_table").DataTable({
      destroy: true,
      dom:
        "<'row mb-2'<'col-sm-9' B><'col-sm-3' >>" +
        "<'row mb-2'<'col-sm-9' l><'col-sm-3' f>>" +
        "<'row'<'col-sm-12' tr>>" +
        "<'row'<'col-sm-7 mt-2 mr-5 pr-4'i><'ml-5' p>>",
      buttons: [
        {
          extend: "csv",
          title,
          download: "open",
          exportOptions: {
            columns: [0, 1],
          },
        },
        {
          extend: "print",
          title,
          messageTop: `<h4 style='text-align:center'>${title}</h4>`,
          download: "open",
          exportOptions: {
            columns: [0, 1],
          },
        },
      ],
    });
  }

  renderRoutesData = () => {
    const routes = this.state.routesData;

    if (routes == null) {
      return null;
    }

    return routes.map((route) => {
      return (
        <tr>
          <td align="center">
            <Badge variant="primary">{route["id"]}</Badge>{" "}
          </td>
          <td align="center">{route["name"]}</td>
          <td align="center">
            <Button
              color="secondary"
              variant="contained"
              onClick={(e) => {
                this.setState(
                  {
                    activeRouteId: route["id"],
                    activeName: route["name"],
                    showUpdateModal: true,
                  },
                  this.fetchActiveCities
                );
              }}
            >
              <FontAwesomeIcon icon={faPenAlt} />
            </Button>

            <Button
              className="mx-1"
              color="primary"
              variant="contained"
              onClick={(e) => {
                this.setState({
                  activeRouteId: route["id"],
                  activeName: route["name"],
                });
              }}
            >
              <FontAwesomeIcon icon={faEye} />
            </Button>

            <Button
              className="mx-1"
              color="danger"
              variant="contained"
              onClick={(e) => {
                if (window.confirm("Delete the item?")) {
                  this.deleteRecord(route["id"]);
                }
              }}
            >
              <FontAwesomeIcon icon={faTrash} />
            </Button>
          </td>
        </tr>
      );
    });
  };

  renderUpdateRouteModal() {
    return (
      <Modal
        show={this.state.showUpdateModal}
        onHide={(e) => this.setState({ showUpdateModal: false })}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Update Party
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form noValidate autoComplete="off">
            <div className="mt-3">
              <Row>
                <Col size="12">
                  <TextField
                    id="routeName"
                    label="Route name"
                    variant="outlined"
                    className="m-2"
                    value={this.state.activeName}
                    onChange={(e) => {
                      this.setState({
                        activeName: e.target.value,
                      });
                    }}
                  />
                </Col>

                <Col xs={6}>
                  <FormControl
                    variant="outlined"
                    className="mt-2 mb-2"
                    fullWidth
                  >
                    <InputLabel>Select City</InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      label={`Select City`}
                      //   defaultValue={city}
                      onChange={(e) =>
                        this.setState({
                          newCities: Array.from(
                            new Set([...this.state.newCities, e.target.value])
                          ),
                        })
                      }
                    >
                      {this.state.cityOptions.map((city) => (
                        <MenuItem value={city}>{city}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Col>
                {/* ))} */}
              </Row>
              <Row className="pl-4 pr-4">
                {this.state.newCities.map((city) => (
                  <Chip
                    key={city}
                    label={city}
                    variant="outlined"
                    className="mr-2 mt-2"
                    onDelete={() =>
                      this.setState({
                        newCities: this.state.newCities.filter(
                          (_city) => city !== _city
                        ),
                      })
                    }
                  />
                ))}
              </Row>
            </div>
            <hr />
            <div className="mt-2 mr-1">
              <Button
                style={{ float: "right" }}
                onClick={(e) => {
                  this.setState({
                    showUpdateModal: false,
                  });
                  this.handleUpdateSubmit(e);
                }}
              >
                Update
              </Button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    );
  }

  render() {
    return (
      <div className="container-fluid border m-0 p-0 main">
        <div className="m-0 p-0">
          <div className="container-fluid border m-0 p-1">
            <div class="btn-group" role="group" aria-label="Basic example">
              <AddNewRoute />
              <Button
                color="primary"
                variant="contained"
                className="mt-1 mr-1 mb-3 ml-5"
                onClick={this.refreshParties}
              >
                <FontAwesomeIcon icon={faSyncAlt} size="2x" />
              </Button>
            </div>

            <Row>
              <Col className="md-4">
                <Row className="ml-0 mr-0">
                  <Col md="12" className="p-0 m-0 measure1">
                    <h6>Routes</h6>
                    <hr />
                    {this.state.isLoading ? (
                      <Box
                        width="100%"
                        height="100px"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <CircularProgress />
                      </Box>
                    ) : (
                      <div>
                        <table
                          id="route_table"
                          className="display"
                          style={{ width: "100%" }}
                        >
                          <thead>
                            <tr align="center">
                              <th>Route Id</th>
                              <th>Route Name</th>
                              <th>Options</th>
                            </tr>
                          </thead>
                          <tbody>{this.renderRoutesData()}</tbody>
                        </table>
                      </div>
                    )}
                  </Col>
                </Row>
              </Col>
              <Col>
                <PartyList
                  routeId={this.state.activeRouteId}
                  routeName={this.state.activeName}
                />
              </Col>
            </Row>
          </div>
        </div>
        {this.renderUpdateRouteModal()}
        <ToastContainer position={toast.POSITION.TOP_RIGHT} autoClose={5000} />
      </div>
    );
  }
}
